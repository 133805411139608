import React, { useState } from "react"
import SEO from "../components/seo"
import Layout from "../components/layout/layout"
import { useStaticQuery, graphql, Link, navigate } from "gatsby"
import "./payment-service-page.scss"
import PageTitle from "../components/page-title/page-title"
import "./contacto.scss"
import ServicePaymentForm from "../components/service-payment-form/service-payment-form"
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion"
import { sendEmailPaymentCompleted } from "../utils/mailService"
import { getUserQuestionnaire } from "../components/questionnaire-wizard/questionnaire-data-service"
import { qDataToArray } from "../utils/helpers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFileContract,
  faFile,
  faFileInvoice,
  faShoppingBasket,
  faHandsHelping,
  fa,
} from "@fortawesome/free-solid-svg-icons"
import { StringyWindup } from "../components/stringy-windup/stringy-windup"
import Fade from "react-reveal/Fade"
import { SloganFlipper } from "../components/slogan/slogan-flipper"

const handlePaymentCompleted = response => {
  const formData = {
    ...response.formData,
    serviceName: "Te llamamos 30'",
    servicePrice: 30,
  }

  // TODO recoger datos del localStorage questionnaireData
  const questionnaireData = getUserQuestionnaire()

  // TODO recoger datos del pago de paypal
  const paymentData = response.paymentData
  // console.log(
  //     "JES onPaymentCompleted",
  //     formData,
  //     questionnaireData,
  //     paymentData
  // );

  sendEmailPaymentCompleted({
    formData,
    questions: questionnaireData ? qDataToArray(questionnaireData) : [],
    paymentData,
  })
}

export default function ConsultaTelefonica() {
  const [status, setStatus] = useState("")

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "anger.png" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fixed(width: 600, height: 600) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  // const userQuestionnaire = true;
  const userQuestionnaire = getUserQuestionnaire()

  const [formData, setFormData] = useState("")

  return (
    <React.Fragment>
      <SEO title="Consulta Experto" />
      <Layout pageId="phone-call">
        <div className="phone-call-container payment-service-page-container">
          <h1>Consulta a un experto</h1>

          {/* <h2>
                        ¿Necesitas un consejo personalizado o ayuda adaptada a
                        tu caso?
                    </h2> */}

          <StringyWindup
            text="Un experto en reclamaciones a abogados te contactará para valorar tu caso en máximo 30 minutos: por teléfono o por el chat de whatsapp, como mejor te venga."
            callbackFn={() => setStatus("step-1")}
          ></StringyWindup>
          {/* 
                    <p>
                        Por <strong>sólo 30€</strong> te proponemos que te llame
                        por teléfono un{" "}
                        <strong>experto en reclamaciones a abogados</strong>{" "}
                        para valorar tu caso en{" "}
                        <strong>máximo 30 minutos</strong>.
                    </p> */}

          <Fade left duration={2000} when={status === "step-1"}>
            <ul className="highlights">
              <li>
                <FontAwesomeIcon
                  icon={faFileInvoice}
                  size="4x"
                  color="#94acc4"
                />
                <div>
                  {!userQuestionnaire && (
                    <React.Fragment>
                      1. Si no lo has hecho todavía, es muy recomendable
                      rellenar previamente nuestro{" "}
                      <Link to="/cuestionario-autovaloracion/">
                        cuestionario de autovaloración
                      </Link>
                    </React.Fragment>
                  )}
                  {userQuestionnaire && (
                    <React.Fragment>
                      1. Has rellenado el cuestionario de autovaloración!{" "}
                      <strong>Este paso ya lo tienes!</strong>, la información
                      proporcionada ayudará a valorar tu caso.
                    </React.Fragment>
                  )}
                </div>
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faFileContract}
                  size="4x"
                  color="#94acc4"
                />
                <div>
                  2. Encarga el servicio{" "}
                  <a href="#formulario-te-llamamos">Te llamamos 30'</a>
                </div>
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faHandsHelping}
                  size="4x"
                  color="#94acc4"
                />
                <div>
                  3. Nuestro experto concertará contigo una conversación
                  telefónica o por whatsapp en 24h
                </div>
              </li>
            </ul>

            <a name="condiciones"></a>
            <Accordion allowZeroExpanded={true}>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    Más detalles sobre el servicio
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    El experto se compromete a trabajar para ti durante{" "}
                    <strong>30 minutos</strong>. Durante esos 30 minutos hablas
                    o whatsappeas con el experto por teléfono. Le planteas tus
                    preguntas y él te contesta.
                  </p>

                  <p>
                    Después de la conversación te enviaremos por correo
                    electrónico un <strong>resumen</strong>.
                  </p>

                  <h2>
                    ¿Cuándo y cómo se pondrá el experto en contacto conmigo?
                  </h2>
                  <p>
                    Una vez efectúes el pago, el experto dentro de las{" "}
                    <strong>24h siguientes</strong> a tu compra te contactará en
                    el número de teléfono que nos hayas facilitado proponiéndote
                    día y hora para prestarte el servicio. Si no te viene bien
                    esa hora, no te preocupes, podréis acordar una cita cuando
                    mejor os convenga.
                  </p>

                  <p>
                    De todas formas y para que no se te olvide, el experto te
                    enviará un <strong>whatsapp de recordatorio</strong> un par
                    de horas antes de darte el servicio
                  </p>

                  <h2>¿Teléfono o whatsapp?</h2>

                  <p>
                    Te ofrecemos estas dos opciones pues algunas personas
                    prefieren la inmediatez de la voz y otras se sienten más
                    cómodas escribiendo y leyendo por el chat whatsapp.
                  </p>

                  <p>
                    <strong>Importante:</strong> contesta enseguida al experto
                    eligiendo tu opción (teléfono o whatsapp) para darte el
                    mejor servicio.
                  </p>

                  <h2>¿Por qué 30 minutos? ¿Y si necesito más tiempo?</h2>
                  <p>
                    <strong>30 minutos es la duración más operativa</strong>{" "}
                    para el contacto con un experto, menos de 30 minutos sería
                    poco tiempo para analizar correctamente tu caso.
                  </p>

                  <p>
                    Si lo necesitas, puedes seguir trabajando con el experto más
                    allá de los 30 minutos iniciales, en ese caso, nuestro
                    experto te informará de cómo
                    <strong>contratar otros 30 minutos más</strong>.
                  </p>

                  <h2>
                    ¿Me devuelven el dinero si quiero anular la consulta o me
                    resulta imposible contestar o responder cuando me contacte
                    el experto?
                  </h2>
                  <p>
                    No te preocupes, nos hacemos cargo de que en algunas
                    ocasiones a nuestros clientes les pueden surgir problemas y
                    finalmente decidan no seguir adelante o incluso, no
                    contesten cuando el experto les llama o escribe un whatsapp.
                    Consulta en las condiciones nuestra{" "}
                    <Link to="/condiciones-legales/">
                      <strong>política de devoluciones</strong>
                    </Link>
                    .
                  </p>

                  <h2>Certificado de seguridad en los pagos</h2>
                  <p>
                    Utilizamos <strong>Paypal</strong> como plataforma de pago
                    que te proporciona una completa seguridad en la realización
                    de pagos.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </Fade>
          <Fade right duration={3000} when={status === "step-1"}>
            <a name="formulario-te-llamamos"></a>
            <ServicePaymentForm
              title="Contrata Te llamamos 30'"
              amount={30}
              onPaymentCompleted={handlePaymentCompleted}
            />
          </Fade>
        </div>

        <SloganFlipper></SloganFlipper>
      </Layout>
    </React.Fragment>
  )
}
